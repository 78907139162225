<template>
    <el-dialog title="Бино кадастр рақамини янгилаш" :visible.sync="toggle" width="450"> 
        <div class="w-full flex flex-col gap-2">
            <div v-for="(item,i) in buildingInfo.cadastr_list" :key="i" class="w-full border p-2 rounded-xl flex items-center gap-3 cursor-pointer" @click="cad_num=item.cadastral_number">
                <div class="w-6 h-6 rounded-full border flex justify-center items-center active-cadaster-radio" :class="cad_num==item.cadastral_number?'border-green-600':''">
                    <div class="w-3 h-3 rounded-full bg-green-500" :class="cad_num==item.cadastral_number?'block':'hidden'"></div>
                </div>
                <div class="flex-1">
                    <p class="text-sm" :class="cad_num==item.cadastral_number?'text-green-600 font-bold':''">{{ item.cadastral_number }} => {{ item.name }}</p>
                </div>
            </div>
        </div> 
        <span slot="footer" class="dialog-footer">
            <el-button @click="toggle = false">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="ChangeCad()">{{ $t('save') }}</el-button>
        </span> 
    </el-dialog>
</template>
<script>
import axios from 'axios';

export default{
    props:{
        value: {
             type: Boolean,
             default: false
         },
         buildingInfo:{
            type:Object,
            default:{},
         }
    },
    data(){
        return {
            loading:false,
            toggle:false,
            cad_num:''
        }
    },
    watch: {
         value: {
             handler(after) {
                this.toggle = after;
                this.is_active = after
                 if (after) {
                     document.body.style.overflowY = 'hidden';
                 }
                 else{
                    document.body.style.overflowY = '';
                 }
             },
             immediate: true,
         },
         toggle() {
             this.$emit("input", this.toggle)
         },

     },
     methods:{
        ChangeCad(){
            if(this.cad_num && this.buildingInfo.id){
                axios.patch(`/building/updating_cadastr/${this.buildingInfo.id}/`,{cadastral_number:this.cad_num})
                .then(()=>{
                    this.toggle=false
                    this.$swal.fire({
                                icon: 'success',
                                title: 'Бино кадастр рақами ўзгартирилди',
                                timer: 3000
                            })
                    this.$emit('changed')

                })
                .catch(()=>{
                    this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_save'),
                                timer: 20000
                            })
                })
            } 
        }
     }

}

</script> 